<template>
	<div>
		<div class="radioWrap">
			<div class="radioItem hoverHand" v-for="(item,index) in list" :key="index" :class="active == index?'active':''" @click="itemClick(index,item)">{{item.name}}</div>
		</div>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。

	export default {
		data() {
			return {
				active: 0,
			}
		},
		props: {
			activeId:{
			},
			list:{
				type:Array,
				default:()=>{
					return []
				}
			}
		},
		methods: {
			itemClick(index){
				// console.log(index,item)
				if(this.active != index ){
					this.active = index;
					this.$emit('change',index)
				}
			}
		},
		components: {

		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		watch: {
			activeId(olds,news){
				// console.log(olds,news);
				if(news){
					this.list.forEach((item,index)=>{
						if(item.id == news){
							this.active = index;
						}
					})
				}
			}
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},
		
	}
</script>

<style lang="less" scoped>
	.radioWrap{
		display: flex;
		flex-wrap: wrap;
	}
	.active{
		border: 1px solid @primaryColorQian !important;
		color: @primaryColorQian;
	}
	.radioItem{
		border: 1px solid #ddd; 
		width: 80px;
		height: 34px;
		border-radius: 5px;
		text-align: center;
		line-height: 34px;
		margin-right: 10px;
	}
</style>