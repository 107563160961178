<template>
	<div class="pageWrap">
		<div class="sellerInfoTitle">个人基础信息</div>
		<div class="sellerCard">
			<div class="sellerCardLeft">
				<div class="sellerItem">
					<div class="sellerTtitle">昵称 :</div>
					<div v-if="editStatus">
						<el-input v-model="input"></el-input>
					</div>
					<div v-if="!editStatus">18664805711</div>
				</div>
				<div class="sellerItem">
					<div class="sellerTtitle">注册时间 :</div>
					<div>2020-12-15 19:12:32</div>
				</div>
				<div class="sellerItem">
					<div class="sellerTtitle">性别 :</div>
					<div v-if="editStatus">
						<sb-sexradio :list="sexList"></sb-sexradio>
					</div>
					<div v-if="!editStatus">
						先生
					</div>
				</div>
				<div class="sellerItem">
					<div class="sellerTtitle">QQ:</div>
					<div v-if="editStatus">
						<el-input v-model="input"></el-input>
					</div>
					<div v-if="!editStatus">24602592</div>
				</div>
			</div>
			<div class="sellerCarRight">
				<sb-upload class="uploadIcon" @handleSuccess="handleSuccess"></sb-upload>
			</div>
		</div>
		<div class="submitButWarp">
			<div class="submitBut"  @click="submitInfo">
				<span v-if="editStatus">保存</span>
				<span v-if="!editStatus">修改</span>
			</div>
		</div>
	</div>
</template>

<script>
	import SbSexradio from '../../components/sex_radio/sex_radio.vue'
	import SbUpload from '../../components/upload/upload.vue'
	export default {
		data() {
			return {
				editStatus: false,
				input: '1',
				sexList: [{
					name: '先生',
					id: 1
				}, {
					name: '女士',
					id: 2
				}, ]
			}
		},
		methods: {
			handleSuccess(res) {
				console.log(res)
			},
			submitInfo() {
				if (this.editStatus){
					this.editStatus = false;
				} else {
					this.editStatus = true;
				}
			}
		},
		components: {
			SbSexradio,
			SbUpload,
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.submitButWarp {
		padding: 60px 0;
		.submitBut:hover{
			background-color: @primaryColorQianHover;
		}
		.submitBut {
			margin: 0 auto;
			width: 240px;
			height: 50px;
			line-height: 50px;
			background-color: @primaryColorQian;
			color: #fff;
			text-align: center;
			border-radius: 5px;
			font-size: 16px;
			cursor: pointer;
		}
	}

	.uploadIcon {
		width: 163px;
		height: 163px;

		/deep/ .no-upload {
			width: 163px;
			height: 163px;
			line-height: 163px;

		}

		/deep/ .icon-B {
			font-size: 60px;
		}
	}

	.sellerCardLeft {
		.sellerItem {
			margin-bottom: 8px;
		}

		/deep/ input:focus {
			border: 1px solid @primaryColorQian  !important;
		}

		/deep/ .el-input__inner {
			height: 34px !important;

		}

		.sellerTtitle {
			line-height: 34px;
			width: 90px;
		}

		.sellerItem {
			display: flex;
			align-items: center;
		}
	}

	.pageWrap {
		padding: 30px 50px;
		background: #fff;
		width: 980px;
		box-sizing: border-box;

		.sellerCard {
			display: flex;
			justify-content: space-between;
			margin: 30px auto 0;
			padding: 60px 90px 60px 60px;
			border-radius: 10px;
		}

		.sellerInfoTitle {
			color: #333;
			padding: 20px;
			border-bottom: 1px solid #eee;
		}
	}
</style>
